/* * {
	box-sizing: border-box;
} */

body {
  background-color: #eeeeee;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: #24292e;
  font-weight: 600;
}

a:hover {
  color: #0366d6;
}
